<template>
    <st-filters-pagination
        stateModule="internalDocuments/list"
        :filters="filters"
        :total="total"
        @change="doFilter"
        :header="$t('INTERNAL_DOCUMENTS.LIST.SUB_TITLE')"
        :moreFilters="true"
    >
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { InternalDocumentsModel } from '@/modules/internal-documents/models/internal-documents-model';

    const { fields, statuses } = InternalDocumentsModel;

    export default {
        name: 'InternalDocumentsListFilter',
        props: {
            toSign: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                fields,
                currentPage: 1
            }
        },
        computed: {
            ...mapGetters({
               total: 'internalDocuments/list/total',
            }),
            filters() {
                let filterFields = [];
                const filterInternalStatus = Object.assign({}, this.fields.internal_status);
                filterInternalStatus.options = this.fields.internal_status.options.filter((el) => el?.display !== false);

                filterFields = [
                    // Primary
                    this.fields.parent_application_identification_number,
                    filterInternalStatus,
                    //Secondary
                    this.fields.assigned_staff_user_name,
                    this.fields.application_date,
                    this.fields.identification_number,
                    this.fields.allocated_document_series
                ];

                const filterSchema = new FilterSchema(filterFields);
                return filterSchema.fields;
            }
        },
        methods: {
            ...mapActions({
                doFetch: 'internalDocuments/list/doFetch',
                doSetFilter: 'internalDocuments/list/doSetFilter'
            }),
            refresh() {
                this.doFilter(this.params);
            },
            async doFilter(params) {
                this.params = params;

                if (this.toSign) {
                    this.params.assigned = true;
                }
                
                this.doFetch(params);
            },
            staffStatusOptions(statusOptions) {
                return statusOptions.sort((a, b) => a.sortkey - b.sortkey );
            }
        },
        created() {
            this.fields.internal_status.options = this.staffStatusOptions(statuses);
            this.fields.identification_number.primary = false;

            const number = this.$route.query.parent_application_identification_number;
            if (number) {
                this.doSetFilter({ key: 'parent_application_identification_number', value: number });
            }
        }
    };
</script>
