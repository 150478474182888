<template>
    <div>
        <st-button
            variant="secondary"
            v-if="!canSign"
            :callback="add"
            v-can:create="'organisationsApplications'"
        >
            <i class="fa fa-plus"></i>
            {{ $t('INTERNAL_DOCUMENTS.ADD_BUTTON') }}
        </st-button>

        <st-button
            variant="light"
            class="st-btn-text-primary"
            v-if="canSign"
            :callback="signDocument"
            :disabled="signBtnDisabled || isSignLoading"
            :spinner="isSignLoading"
        >
            <i class="fa fa-save"></i>
            {{ $t("INTERNAL_DOCUMENTS.LIST.SIGN_DOCUMENT_BULK") }}
        </st-button>

        <st-button
        v-if="showRefreshBtn"
        variant="dark"
        customClass="application-refresh-button"
        :callback="refresh"
        >
            <i class="fas fa-sync"></i>
            {{ $t("APPLICATION.LIST.REFRESH_BUTTON") }}
        </st-button>

        <confirm-sign-document-modal
            ref="confirm-sign-document-modal"
            :bulkSigning="true"
            :id="selectedRows"
            @signed="refresh"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ConfirmSignDocumentModal from '@/modules/internal-documents/components/ConfirmSignDocumentModal.vue';
import Message from '@/shared/message/message';

    export default {
        name: 'InternalDocumentsListToolbar',
        components: {
            ConfirmSignDocumentModal
        },
        props: {
            canSign: {
                type: Boolean,
                default: false
            },
            showRefreshBtn: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapGetters({
                selectedRows: "internalDocuments/list/selectedRows",
                loading: 'shared/loading',
                appConfig: 'shared/appConfig',
            }),
            signBtnDisabled() {
                return !this.selectedRows.length || this.selectedRows.length == 1;
            },
            isSignLoading() {
                return this.loading["internalDocuments/signDocumentBulk"];
            }
        },
        methods: {
            ...mapActions({
                setSelectedRows: 'internalDocuments/list/doSelect',
                getInternalDocumentsToSign: 'getAssignedApplicationsCount',
                signDocumentBulk: 'internalDocuments/form/signDocumentBulk',
            }),
            add() {
                this.$emit('add');
            },
            async refresh() {
                this.refreshInternalDocuments();
                this.setSelectedRows([]);
                // Update internal documents count on side menu
                const payload = {
                    params: {
                        skip: 0,
                        limit: this.appConfig.RECORDS_PER_PAGE,
                        pagination: true,
                        internal: true,
                        assigned: true,
                    },
                };
                this.getInternalDocumentsToSign(payload);
            },
            signDocument() {
                let ids = this.selectedRows.map((el) => el.id);

                this.signDocumentBulk(ids).then((response) => {
                    this.$refs['confirm-sign-document-modal'].show(response);
                }).catch(() => {
                    Message.error('APPLICATION.VIEW.SIGN_DOCUMENT_ERROR');
                });
            },
            refreshInternalDocuments() {
                this.$emit('refreshInternalDocuments');
            }
        },
    };
</script>
