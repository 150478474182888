<template>
    <st-modal
        ref="modal"
        hide-header-delimiter
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        hideable
        size="md"
        :title="$t('INTERNAL_DOCUMENTS.SIGN_STATUS.TITLE')"
        :id="modalId"
        :hideHeaderDelimiter="false"
    >
        <template #body>
            <div class="fs-14">
                <div class="row pt-0 pb-2">
                    <div class="col-md-6 font-weight-bold">{{$t('INTERNAL_DOCUMENTS.SIGN_STATUS.IDENTIFICATION_NUMBER')}}:</div>
                    <div class="col-md-6">{{identificationNumber}}</div>
                </div>
                <div class="row pb-2">
                    <div class="col-md-6 font-weight-bold">{{$t('INTERNAL_DOCUMENTS.SIGN_STATUS.PARENT_IDENTIFICATION_NUMBER')}}:</div>
                    <div class="col-md-6">{{parentIdentificationNumber}}</div>
                </div>
                <div class="row pb-2">
                    <div class="col-md-6 font-weight-bold">{{$t('INTERNAL_DOCUMENTS.SIGN_STATUS.LAST_SIGNING_DATE')}}:</div>
                    <div class="col-md-6">{{lastSignDate}}</div>
                </div>
                <div class="row">
                    <div class="col-md-6 font-weight-bold">{{$t('INTERNAL_DOCUMENTS.SIGN_STATUS.SIGNING_BY')}}:</div>
                    <div class="col-md-6">{{toSignBy}}</div>
                </div>
            </div>
        </template>
    </st-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { generateRandomId } from '@/core/helpers/globalMethods';
import moment from 'moment';

export default {
    name: "InternalDocumentSigningStatusModal",
    data() {
        return {
            processingAction: [],
            resolvedAction: [],
            modalId: `st-modal-sign-status-${generateRandomId()}`,
            assignedUser: {}
        };
    },
    computed: {
        ...mapGetters({
            record: "internalDocuments/form/record",
        }),
        lastSignDate() {
            if (this.resolvedAction.length && this.resolvedAction[0].action_type === 'SIGN_DOCUMENT') {
                return moment(this.resolvedAction[0].updated_date).format('DD.MM.YYYY HH:mm');
            }

            return '-';
        },
        toSignBy() {
            if (this.processingAction.length) {
                return this.processingAction[0].function_type;
            }

            return '-';
        },
        identificationNumber() {
            return this.record.identification_number || '-';
        },
        parentIdentificationNumber() {
            return this.record.parent_application_identification_number || '-';
        },
    },
    methods: {
        ...mapActions({
            doFindUserActions: "internalDocuments/form/findUserActions",
            resetUserActions: "internalDocuments/form/doResetUserActions",
            doFind: "internalDocuments/form/find",
            findUser: "internalDocuments/form/doFindAssignedStaffAccount"
        }),
        async show() {
            await this.getUserActions();
            this.$refs["modal"].show();
        },
        async hide() {
            this.resetUserActions();
            await this.doFind({id: this.record.id, loadStaff: true});

            this.$refs["modal"].hide();
        },
        async getUserActions() {
            if (this.record) {
                const paramsProcessing = {
                    query: `application_id eq ${this.record.id} and status eq PROCESSING`,
                    sort: '-created_date'
                };

                const paramsResolved = {
                    query: `application_id eq ${this.record.id} and status eq RESOLVED`,
                    sort: '-created_date',
                    limit: 1
                };

                this.processingAction = await this.doFindUserActions(paramsProcessing);
                this.resolvedAction = await this.doFindUserActions(paramsResolved);
            }
        }
    },
};
</script>

<style scoped>
    .fs-14 {
        font-size: 14px;
    }
</style>
